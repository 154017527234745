:root {
   --bodyColor: rgb(20, 20, 20);
	--contrastingBodyColor: rgb(30, 30, 30);
	--textColor: white;
	--contrastingTextColor: black;
	--othersMessageColor: #36a568;
	--yourMessageColor: #45daff;
   --placeholderColor: gray;
	--positiveButtonColor: #36ff90;
	--positiveButtonHoverColor: #36a568;
	--hamburgerColor: brightness(100%);
}
$borderRadius: 15px;
html, body {
   overflow-y: hidden;
	overflow-x: visible;
   background-color: var(--bodyColor);
   margin: 0;
}
@mixin scrollBar {
   ::-webkit-scrollbar {
      width: 15px;
   }
   ::-webkit-scrollbar-track {
      background-color: var(--bodyColor);
   }
   ::-webkit-scrollbar-thumb {
      background: var(--contrastingBodyColor);
      border-radius: 2px;
   }
}
@include scrollBar;

@import 'mixins';

.loaderContainer{
	height: 100vh;
	display:grid;
	place-items:center;
	.loader {
		border: 16px solid #f3f3f3;
		border-radius: 50%;
		border-top: 16px solid #0099ff;
		width: 120px;
		height: 120px;
		-webkit-animation: spin 1s linear infinite; /* Safari */
		animation: spin 1s linear infinite;
	}
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes fadeIn{
	0%{ opacity: 0;}
	100%{	opacity: 1;}
}
.App {
	height: 100vh;
	width: 100vw;
	display: flex;
	font-family: 'Roboto', sans-serif;
	animation: fadeIn 1s;
	/*pop up components*/
	@import './components/popUps';

	.InternetWarning {
		display: block;
		position: fixed;
		background-color: var(--contrastingBodyColor);
		border-width: 1px;
		border-radius: 10px;
		text-align: center;
		color: var(--textColor);
		font-size: 30px;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 20px;
	}
	.GrayBackground {
		z-index: 1;
		position: fixed;
		height: 100vh;
		width: 100vw;
		background-color: black;
		opacity: 0.4;
		transition: 0.2s;
      cursor: pointer;
		&:hover {
			opacity: 0.3;
		}
	}

	.HamburgerMenu {
		background-color: var(--bodyColor);
		display: block;
		position: absolute;
		top: 50px;
		right: 0;
		text-align: center;
		padding: 50px;
		box-shadow: -15px 16px 20px 0px #00000024;
		border-radius: 0 0 0 $borderRadius;
		.youAreLoggedInAs {
			margin: 20px 0 20px 0;
			color: var(--yourMessageColor);
			font-size: 30px;
		}
		button {
			font-family: inherit;
			text-decoration: none;
			color: var(--contrastingTextColor);
			margin: 10px 0 10px 0;
			text-align: center;
			outline: none;
			-webkit-tap-highlight-color: transparent;
			font-size: 30px;
			cursor: pointer;
			transition: all 0.2s cubic-bezier(0.23, 1.65, 0.38, 1.65);
			border: none;
			border-radius: 5px;
			padding: 3px 5px 3px 5px;
			background-color: var(--positiveButtonColor);
			width: 200px;
			&:hover {
            box-shadow: -2px 3px 20px 0px #36a5682c;
				background-color: var(--positiveButtonHoverColor);
				margin: 3px 0 3px 0;
				padding: 10px;
				color: var(--textColor);
			}
		}
		.links {
			margin: 20px 0 0 0;
			pre {
				display: inline;
				color: var(--textColor);
				font-size: 20px;
			}
			a {
				color: var(--textColor);
				font-size: 20px;
			}
		}
	}
	.ChatMenu {
		display: block;
		height: 100%;
		width: 310px;
		background-color: var(--contrastingBodyColor);
		color: var(--textColor);
		overflow: auto;
		font-size: 26px;
		text-align: center;
		.topBar {
			display: none;
		}
		.individualChat {
			padding: 2.5px;
			cursor: pointer;
			user-select: none;
			&:hover {
            box-shadow: -12px 3px 20px 0px #0000006b;
				background-color: var(--bodyColor);
			}
		}
		.createChatButton {
			outline: none;
			border: none;
			background-color: var(--contrastingBodyColor);
			color: var(--positiveButtonColor);
			padding: 0 25px 0 25px;
			position: absolute;
			border-radius: 360px;
			font-size: 80px;
			bottom: 20px;
			left: 20px;
			cursor: pointer;
         box-shadow: -9px 3px 20px 0px #0000004f;
         transition:.2s;
			&:hover {
				background-color: var(--bodyColor);
            box-shadow: -9px 3px 20px 0px #00000027;
			}
		}
	}
	@import './components/rightContainer';
}
@import 'queries';
